<template>
  <div class="items">
    <template v-if="items.length">
    <button
      class="item"
      :class="{ 'is-selected': index === selectedIndex }"
      v-for="(item, index) in items"
      :key="index"
      @click="selectItem(index)"
    >
      {{ item.tagName || item.name }}
    </button>
    </template>
    <template v-else>
        <mdc-text tag="div" typo="body2" style="color:#999">None found</mdc-text>
    </template>
  </div>
</template>

<script>
export default {
  name: 'TagList',
  props: {
    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      selectedIndex: 0
    }
  },

  watch: {
    items () {
      this.selectedIndex = 0
    }
  },

  methods: {
    onKeyDown ({ event }) {
      // console.log('TagList.onKeyDown', event)
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      if (event.key === 'Tab') {
        this.enterHandler()
        return true
      }

      return false
    },

    upHandler () {
      this.selectedIndex = (this.selectedIndex + this.items.length) % (this.items.length + 1)
    },

    downHandler () {
      this.selectedIndex = (this.selectedIndex + 1) % (this.items.length + 1)
    },

    enterHandler () {
      this.selectItem(this.selectedIndex)
    },

    selectItem (index) {
      let item
      if (index === this.items.length) {
        item = 'date'
      } else {
        item = this.items[index]
      }

      if (item !== 'date') {
        // console.log('Taglist.selectItem', item)
        this.command({ 'data-hashtag': item.tagName || item.name, 'data-hashtagid': item.id })
      } else {
        // TODO: trigger date picker
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.items {
  margin: 0;
  z-index: 10;
  border-radius: 6px;
  min-width: 100px;
  max-width: max-content;
  color: #6184ad;
  border: none;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
  background: #fff;
}

.item {
  display: inline-flex;
  width: 100%;
  cursor: pointer;
  color: #6184ad;
  white-space: normal;
  padding: 5px 12px;
  background: transparent;
  border: none;
  text-align: left;
  font-size: 14px;
  &:hover {
    background: #ebf3fc;
  }
  &.is-selected {
    background: #ebf3fc;
  }
}
</style>
