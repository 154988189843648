<template>
  <div class="items">
    <button
      class="item"
      :class="{ 'is-selected': index === selectedIndex }"
      v-for="(item, index) in items"
      :key="index"
      @click="selectItem(index)"
    >
      <span :title="item.notePageType + ' > ' + getParent(item)" >
      <base-svg-icon  v-if="item.notePageType === 'private'" name="inidividual" class="private-icon" title="Private Page"  style="font-size:16px;margin-right:6px;"  />
      <base-svg-icon  v-if="item.notePageType === 'initiative'" name="rocket" class="initiative-icon" title="Initiative Page"  style="font-size:16px;margin-right:6px;"   />
      <base-svg-icon  v-if="item.notePageType === 'team'" name="team" class="team-icon" title="Team Page"  style="font-size:16px;margin-right:6px;"  />
      </span>
      {{ item.pageName }}
    </button>
  </div>
</template>

<script>
import BaseSvgIcon from '../../../components/flexibly/BaseSvgIcon'

export default {
  name: 'LinkList',
  props: {
    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    }
  },
  components: {
    BaseSvgIcon
  },
  data () {
    return {
      selectedIndex: 0
    }
  },

  watch: {
    items () {
      this.selectedIndex = 0
    }
  },

  methods: {
    onKeyDown ({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      if (event.key === 'Tab') {
        this.tabHandler()
        return true
      }

      return false
    },

    getParent (item) {
      if (item.notePageType === 'private') {
        return item.ownerName || ''
      }
      if (item.notePageType === 'initiative') {
        return item.initiativeName || ''
      }
      if (item.notePageType === 'team') {
        return item.teamName || ''
      }
      return item.workspaceName || ''
    },
    upHandler () {
      this.selectedIndex = (this.selectedIndex + this.items.length) % (this.items.length)
    },

    downHandler () {
      this.selectedIndex = (this.selectedIndex + 1) % (this.items.length)
    },

    tabHandler () {
      this.selectItem(this.selectedIndex)
    },

    enterHandler () {
      this.selectItem(this.selectedIndex)
    },

    selectItem (index) {
      const item = this.items[index]
      this.command({ id: item.pageName, 'data-link-id': item.id, 'data-link-text': item.pageName, 'data-label': item.pageName, 'data-link': item.pageLink })
    }
  }
}
</script>

<style lang="scss" scoped>
.items {
  margin: 0;
  z-index: 10;
  border-radius: 6px;
  min-width: 100px;
  max-width: max-content;
  color: #6184ad;
  border: none;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 15%);
  background: #fff;
}

.item {
  display: inline-flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
  color: #6184ad;
  white-space: normal;
  padding: 5px 12px;
  background: transparent;
  border: none;
  text-align: left;
  font-size: 14px;
  &:hover {
    background: #ebf3fc;
  }
  &.is-selected {
    background: #ebf3fc;
  }
}
</style>
