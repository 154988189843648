import { Node, mergeAttributes } from '@tiptap/core'
// import { textblockTypeInputRule } from 'prosemirror-inputrules'

// const inputRegex = /\/private $/

const Private = Node.create({
  name: 'private',

  group: 'block',
  content: 'text*',
  code: true,
  locked: true,

  addOptions () {
    return {
      userid: '',
      HTMLAttributes: {}
    }
  },

  addAttributes () {
    return {
      userid: {
        default: '',
        // Customize the HTML parsing (for example, to load the initial content)
        parseHTML (element) {
          // console.log('addAttributes.parseHTML', element, this)
          return {
            userid: element.getAttribute('data-userid')
          }
        },
        // … and customize the HTML rendering.
        renderHTML (attributes) {
          // console.log('addAttributes.renderHTML', attributes, this)
          return {
            'data-userid': attributes.userid
          }
        }
      }
    }
  },

  parseHTML () {
    return [
      {
        tag: 'div[data-type="private"]'
      }
    ]
  },

  renderHTML ({ node, HTMLAttributes }) {
    // console.log('Private.renderHTML', node, HTMLAttributes, this)
    return ['div', mergeAttributes({
      'data-type': 'private',
      'data-userid': this.options.userid,
      title: 'This section is only viewable by you',
      class: 'private'
    },
    HTMLAttributes), 0]
  },

  addCommands () {
    return {
      /**
       * Toggle a Private node
       */
      togglePrivate: () => ({ commands }) => {
        // console.log('Private.toggleMention', commands)
        return commands.toggleNode(this.type, 'paragraph', {
          userid: this.options.userid
        })
      }
    }
  },

  // addInputRules () {
  //   return [
  //     textblockTypeInputRule(inputRegex, this.type, {
  //       userid: this.options.userid
  //     })
  //   ]
  // },

  addKeyboardShortcuts () {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        const { selection } = state
        // const { empty, anchor } = selection
        // console.log('private.backspace', tr, state, selection)
        const { empty, anchor } = selection

        if (!empty) {
          return false
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          // console.log('private.backspace selection', node, pos)
        })
      }),
      'Mod-Shift-p': () => this.editor.commands.togglePrivate()
    }
  },

  toJSON () {
    // console.log('private.toJSON')
  }
})

export default Private
