
import { Extension } from '@tiptap/core'

const NoteExtension = Extension.create({
  addKeyboardShortcuts () {
    return {
      Enter: () => this.editor.commands.command(({ tr, state }) => {
        // console.log('NoteExtension.KeyboardShortcuts.Enter', tr, state, this)
        return this.options.editorEnter(state, tr, this.view)
      }),
      Tab: () => this.editor.commands.command(({ tr, state }) => {
        // console.log('NoteExtension.KeyboardShortcuts.Tab', tr, state, this)
        return this.options.tab(state, tr, this.view)
      }),
      'Shift-Tab': () => this.editor.commands.command(({ tr, state }) => {
        // console.log('NoteExtension.KeyboardShortcuts.Shift-Tab', tr, state, this)
        return this.options.shiftTab(state, tr, this.view)
      }),
      'Control-ArrowUp': () => this.editor.commands.command(({ tr, state }) => {
        // console.log('NoteExtension.KeyboardShortcuts.Shift-Tab', tr, state, this)
        return this.options.blockUp(state, tr, this.view)
      }),
      'Control-ArrowDown': () => this.editor.commands.command(({ tr, state }) => {
        // console.log('NoteExtension.KeyboardShortcuts.Shift-Tab', tr, state, this)
        return this.options.blockDown(state, tr, this.view)
      }),
      'Mod-Alt-4': () => {
        // console.log('NoteExtension.keys.Mod-Alt-4')
        return this.editor.chain().focus().toggleTaskList().run()
      },
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        // console.log('NoteExtension.KeyboardShortcuts.Backspace', tr, state, this)
        return this.options.backSpace(state, tr, this.view)
      })

    }
  }
})

export default NoteExtension
