<template>
  <div>
    <div :id="id" class="editor" v-if="editor" v-bind:class="{'share-proposal' : contentKey === 'shareProposal', 'content-focused': contentFocus, 'no-selection': !selectedNote }" >

        <div
          v-if="!externalMenu && !hideMenu"
          class="editor__floating-menu editor__menu menubar"
          :class="{ 'is-active': editor.isActive }"
          :style="`top: ${menuPosition.top}px`"
        >
            <button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('bold') }"
              @click="editor.chain().focus().toggleBold().focus().run()"
            >
              <mdc-icon icon="format_bold"/>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('italic') }"
              @click="editor.chain().focus().toggleItalic().focus().run()"
            >
              <mdc-icon icon="format_italic"/>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('strike') }"
              @click="editor.chain().focus().toggleStrike().focus().run()"
            >
              <mdc-icon icon="format_strikethrough"/>
            </button>

            <button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('underline') }"
              @click="editor.chain().focus().toggleUnderline().focus().run()"
            >
              <mdc-icon icon="format_underline"/>
            </button>

            <button
              v-if="!reducedOptions"
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('todo_list') }"
              @click="editor.chain().focus().toggleTaskList().focus().run()"
            >
              <mdc-icon outlined icon="check_box"/>
            </button>

            <button
              v-if="!reducedOptions"
              class="menubar__button"
              @click="shiftTab"
            >
              <mdc-icon outlined icon="format_indent_decrease"/>
            </button>

            <button
              v-if="!reducedOptions"
              class="menubar__button"
              @click="tab"
            >
              <mdc-icon outlined icon="format_indent_increase"/>
            </button>
        </div>

      <div
        v-if="!externalMenu && !hideMenu"
        class="menububble editor__menu"
        :class="{ 'is-active': isBubbleActive }"
        :style="`left: ${bubbleMenuPosition.left}px; bottom: ${bubbleMenuPosition.bottom}px;`"
      >
        <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
          <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
          <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
            <mdc-icon icon="clear" />
          </button>
        </form>

        <template v-else>

        <button
          class="menububble__button"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().focus().run()"
        >
          <mdc-icon icon="format_bold"/>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().focus().run()"
        >
          <mdc-icon icon="format_italic"/>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click="editor.chain().focus().toggleStrike().focus().run()"
        >
          <mdc-icon icon="format_strikethrough"/>
        </button>

        <button
          class="menububble__button"
          :class="{ 'is-active': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().focus().run()"
        >
          <mdc-icon icon="format_underline"/>
        </button>

        <button
          v-if="!reducedOptions"
          class="menububble__button"
          :class="{ 'is-active': editor.isActive('link') }"
          @click="showLinkMenu(getMarkAttrs('link'))"
        >
          <mdc-icon icon="link"/>
        </button>
        </template>
      </div>

    <editor-content @blur.native="setFocus(false)" @focus.native="setFocus(true)" @click.native="handleContentClick" class="editor__content" :editor="editor" />

  </div>
  <div class="scrim suggestion-scrim" @click="onExit" v-if="showSuggestions"></div>
  <div class="suggestion-list" :style="{ top: suggestionPos.top, left: suggestionPos.left }" v-show="showSuggestions" ref="suggestions">
      <template v-if="hasResults && !showDatePicker">
        <div
          v-for="(suggestion, index) in filteredSuggestions"
          :key="suggestion.id"
          class="suggestion-list__item"
          :class="{ 'is-selected': navigatedSuggestionIndex === index }"
          @click="selectOption(suggestion)"
        >
          <span v-if="!hashtagActive">
            <span class="avatar">
              <img v-if="suggestion.photoURL" :src="suggestion.photoURL">
              <span v-else class="default-avatar">{{suggestion.initials}}</span>
            </span>
          </span>
          {{ suggestion.name }}
        </div>
      </template>
      <div v-else-if="!this.filteredSuggestions.length" class="suggestion-list__item is-empty">
        None found
      </div>
      <div v-if="!hashtagActive"
      :class="{'width-seperator': hasResults }"
      class="suggestion-list__item add-date">
        <div class="add-date-header" @click="showDatePicker = true">
          <mdc-icon outline icon="calendar_today"/>
          <span>Add a due date</span>
        </div>
        <datepicker v-model="datepicked" v-show="showDatePicker" @input="dateSelected" :inline="true" ></datepicker>
      </div>
  </div>

  <div v-if="annotationsEnabled && comments.length" class="annotationMenu">
    <div class="annotationLine" v-for="comment in comments" :key="comment.id">
        <span class="content">{{ comment.data }}</span>

        <button @click="updateComment(comment.id)">
          <mdc-icon icon="create" outline />
        </button>

        <button @click="deleteComment(comment.id)">
          <mdc-icon icon="delete" outline />
        </button>
      </div>
  </div>

  <template v-if="editor">
    <div class="version-section" v-if="editor.options.history">
    </div>
  </template>

  <component :is="'style'">
    .private[data-userid='{{ userId }}'] {
      visibility: visible;
      height:auto;
    }
  </component>
  <component v-if="block" :is="'style'">
    #{{ id }} .draggable-item {
      display:none;
    }
    #{{ id }} .draggable-item[data-note-id='{{ block }}'] {
      display: flex;
    }
  </component>

  </div>

</template>

<script>
import moment from 'moment'
import tippy from 'tippy.js'
import { mapActions } from 'vuex'
import { dateDisplayFormat, parseDisplayDate } from '../utils/time'
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Strike from '@tiptap/extension-strike'
import Italic from '@tiptap/extension-italic'
import { Underline } from '@tiptap/extension-underline'
// import Heading from '@tiptap/extension-heading'
import Blockquote from '@tiptap/extension-blockquote'
import TextAlign from '@tiptap/extension-text-align'
import Gapcursor from '@tiptap/extension-gapcursor'
import History from '@tiptap/extension-history'

import NoteDocument from '../utils/tiptap/v2/NoteDocument'
import Mention from '@tiptap/extension-mention'
import MentionList from '../utils/tiptap/v2/MentionList'
import Hashtag from '../utils/tiptap/v2/Hashtag'
import TagList from '../utils/tiptap/v2/TagList'
import NoteKeyExtension from '../utils/tiptap/v2/NoteKeyExtension'
import Private from '../utils/tiptap/v2/Private'
import PageLinkSuggestion from '../utils/tiptap/v2/PageLinkSuggestion'
import LinkList from '../utils/tiptap/v2/LinkList'
import PlaceHolder from '../utils/tiptap/v2/PlaceHolder'

import NoteBlockExtension from '../utils/tiptap/v2/NoteBlockExtension'

import BoldMark from '../utils/tiptap/v2/BoldMark'
import ItalicMark from '../utils/tiptap/v2/ItalicMark'
import UnderlineMark from '../utils/tiptap/v2/UnderlineMark'
import StrikeMark from '../utils/tiptap/v2/StrikeMark'
// import { Annotation } from '../utils/tiptap/v2/annotations/Annotation'

import TaskList from '@tiptap/extension-task-list'
import CustomTaskItem from '../utils/tiptap/v2/CustomTaskItem'

// Removed collaboration mode from tiptap

export default {
  props: {
    showComments: {
      type: Boolean,
      default: false
    },
    disableNoteBlockSharing: {
      type: Boolean,
      default: false
    },
    content: {
      type: String
    },
    contentKey: {
      type: String
    },
    title: {
      type: String
    },
    focused: {
      type: Boolean,
      default: false
    },
    customCommands: {
    },
    mentionSuggestions: {
    },
    disableTags: {
      type: Boolean,
      default: false
    },
    hashtags: {
    },
    pagelinks: {
      default () {
        return []
      }
    },
    usePageLinkSuggestions: {
      type: Boolean,
      default: false
    },
    emitEnter: {
      type: Boolean,
      default: false
    },
    emitTab: {
      type: Boolean,
      default: false
    },
    emitShiftTab: {
      type: Boolean,
      default: false
    },
    emitBackspace: {
      type: Boolean,
      default: false
    },
    reducedOptions: {
      type: Boolean,
      default: false
    },
    useWebSocketProvider: {
      type: Boolean,
      default: false
    },
    externalMenu: {
      type: Boolean,
      default: false
    },
    hideMenu: {
      type: Boolean,
      default: false
    },
    noteBlocksEnabled: {
      type: Boolean,
      default: false
    },
    annotationsEnabled: {
      type: Boolean,
      default: false
    },
    doc: {
      type: String,
      default: 'flexibly-test-document'
    },
    yDoc: {
    },
    yProvider: {
    },
    docState: {
    },
    block: {
    },
    user: {
    },
    accessToken: {
    },
    workspaceId: {
    },
    teamId: {
    },
    forDate: {
    }
  },
  components: {
    Datepicker: () => import('vuejs-datepicker'),
    EditorContent
  },
  data () {
    return {
      id: '',
      ydoc: null,
      provider: null,
      idxdbPersistence: null,
      ignoreFirstTextUpdate: false,
      userId: null,
      userName: null,
      users: [],
      userColor: null,
      versions: null,
      versionContent: null,
      revertDoc: null,
      linkUrl: null,
      linkMenuIsActive: false,
      contentFocus: false,
      blurTimeout: null,
      keepInBounds: true,
      hashtagActive: false,
      inSuggestion: false,
      suggestionPos: { top: 0, left: 0 },
      showDatePicker: false,
      datepicked: new Date(),
      editor: null,
      isBubbleActive: false,
      menuPosition: { left: 0, top: 0 },
      bubbleMenuPosition: { left: 0, top: 0 },
      query: null,
      suggestionRange: null,
      filteredSuggestions: [],
      navigatedSuggestionIndex: 0,
      insertMention: ({ attrs, range }) => {
        // const tr = this.editor.view.state.tr
        // tr.delete(range.from, range.to)
        // this.editor.view.dispatch(tr)
        // this.editor.commands.profiletag(attrs)
      },
      insertHashTag: () => {},
      htmlContent: '',
      updateTimer: null,
      comments: []
    }
  },
  methods: {
    ...mapActions({
      getNotePage: 'teams/getNotePage'
    }),
    getRndId () {
      this.id = 'tiptap-' + Math.floor(100000 + Math.random() * 900000)
    },
    getPageLinkById (id) {
      const pageMap = {}
      for (const page of this.pagelinks) {
        pageMap[page.id] = page
      }
      return pageMap[id]
    },
    updateComment (id) {
      const comment = this.comments.find(item => {
        return id === item.id
      })
      const data = prompt('Comment', comment.data)
      this.editor.commands.updateAnnotation(id, data)
    },
    deleteComment (id) {
      this.editor.commands.deleteAnnotation(id)
    },
    dateSelected (event) {
      this.selectOptionDate(event)
    },
    showLinkMenu (attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    onExit () {
      this.suggestionPos = { top: 'unset', left: 'unset' }
      this.query = null
      this.filteredSuggestions = []
      this.suggestionRange = null
      this.navigatedSuggestionIndex = 0
      this.destroyPopup()
      this.$nextTick(() => {
        // this.editor.focus()
      })
    },
    command () {
      return this.editor.chain()
    },
    onFocus (event) {
      if (this.blurTimeout) {
        clearTimeout(this.blurTimeout)
      }
      this.$emit('focus')
      this.contentFocus = true
    },
    onBlur (event) {
      if (this.blurTimeout) {
        clearTimeout(this.blurTimeout)
      }
      const self = this
      this.blurTimeout = setTimeout(() => {
        self.contentFocus = false
      }, 300)
    },
    setFocus (event) {
      if (event) {
        this.$emit('focus')
      } else {
        this.$emit('blur')
      }
    },
    focus () {
      this.$nextTick(() => {
        // this.editor.focus()
      })
    },
    hideLinkMenu () {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl (command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    onTextUpdate () {
      if (this.ignoreFirstTextUpdate) {
        this.ignoreFirstTextUpdate = false
      } else {
        if (this.updateTimer) {
          clearTimeout(this.updateTimer)
        }
        const noteBlockEmptyTag = this.editor.getHTML().match(/<\s*div[^>]*><p>[ \s]*<\/p><\/div>/g)
        let finalBlock = this.editor.getHTML()
        if (noteBlockEmptyTag && noteBlockEmptyTag.length > 0) {
          noteBlockEmptyTag.map(text => {
            finalBlock = finalBlock.replace(text, '')
            return finalBlock
          })
        }
        const text = finalBlock
        this.htmlContent = finalBlock
        if (this.customCommands) {
          for (const command of this.customCommands) {
            if (text.includes(command)) {
              const newText = text.replace(command, '')
              this.htmlContent = newText
              // this.editor.setContent(newText)
              // TODO: This wont work for collaborative (need to undo the transaction)
              this.$emit('onCommand', command)
            }
          }
        }
        if (this.inSuggestion) {
          this.inSuggestion = false
          this.filteredSuggestions = []
        }
        const self = this
        this.updateTimer = setTimeout(function () {
          self.$emit('onTextUpdate', text, self.contentKey)
        }, 300)
      }
    },
    getMentions () {
      function findMention (node) {
        let results = []
        if (node.type === 'mention') {
          // console.log('getMentions.findMention', node.type, node.attrs)
          if (node.attrs.id && node.attrs.label) {
            return [node.attrs.id]
          }
        } else if (node.content) {
          for (const content of node.content) {
            results = [...results, ...findMention(content)].flat()
          }
        }
        return [...new Set(results)]
      }
      const json = this.editor.getJSON()
      return findMention(json)
    },
    getDates () {
      function findMention (node) {
        let results = []
        if (node.type === 'profiletag') {
          if (node.attrs.id === 'date') {
            return [node.attrs.date]
          }
        } else if (node.content) {
          for (const content of node.content) {
            results = [...results, ...findMention(content)].flat()
          }
        }
        return results
      }
      const json = this.editor.getJSON()
      return findMention(json)
    },
    getTags () {
      function findTag (node) {
        let results = []
        if (node.type === 'hashtag') {
          return [node.attrs['data-hashtag']]
        } else if (node.content) {
          for (const content of node.content) {
            results = [...results, ...findTag(content)].flat()
          }
        }
        return results
      }
      const json = this.editor.getJSON()
      return findTag(json)
    },
    getTagIds () {
      function findTagId (node) {
        let results = []
        if (node.type === 'hashtag') {
          return [node.attrs['data-hashtag']]
        } else if (node.content) {
          for (const content of node.content) {
            results = [...results, ...findTagId(content)].flat()
          }
        }
        return results
      }
      const json = this.editor.getJSON()
      return findTagId(json)
    },
    getContent () {
      return this.editor.getHTML()
    },
    tab (state, dispatch, view) {
      if (this.emitTab) {
        this.$emit('tab', state, dispatch, view)
        return true
      }
      return false
    },
    shiftTab (state, dispatch, view) {
      if (this.emitShiftTab) {
        this.$emit('shiftTab', state, dispatch, view)
        return true
      }
      return false
    },
    backSpace (state, dispatch, view) {
      if (this.emitBackspace && this.editor.view.dom.textContent === '') {
        this.$emit('backSpace', state, dispatch, view)
        return true
      }
      return false
    },
    blockUp (state, dispatch, view) {
      return true
    },
    blockDown (state, dispatch, view) {
      return true
    },
    editorEnter (state, dispatch, view) {
      if (this.emitEnter) {
        this.$emit('editorEnter', state, dispatch, view)
        return true
      }
      return false
    },
    // navigate to the previous item
    // if it's the first item, navigate to the last one
    upHandler () {
      let optionsLength = this.filteredSuggestions.length
      if (!this.hashtagActive) {
        optionsLength++
      }
      this.navigatedSuggestionIndex = ((this.navigatedSuggestionIndex + optionsLength) - 1) % optionsLength
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler () {
      let optionsLength = this.filteredSuggestions.length
      if (!this.hashtagActive) {
        optionsLength++
      }
      this.navigatedSuggestionIndex = (this.navigatedSuggestionIndex + 1) % optionsLength
    },
    enterHandler () {
      if (this.hashtagActive) {
        const tag = this.hashtags[this.navigatedSuggestionIndex]
        if (tag) {
          this.selectHashTag(tag)
        }
      } else {
        const suggestion = this.filteredSuggestions[this.navigatedSuggestionIndex]
        if (suggestion) {
          this.selectSuggestion(suggestion)
        } else {
          this.selectSuggestionDate(suggestion)
        }
      }
    },
    selectOptionDate (date) {
      this.selectSuggestionDate(dateDisplayFormat(date))
    },
    // we have to replace our suggestion text with a mention
    // so it's important to pass also the position of your suggestion text
    selectOption (suggestion) {
      if (this.hashtagActive) {
        this.selectHashTag(suggestion)
      } else {
        this.selectSuggestion(suggestion)
      }
      this.filteredSuggestions = []
    },
    onTransaction ({ transaction }) {
    },
    handleContentClick (ev) {
      if (ev.target.matches('.pagelink')) {
        ev.preventDefault()
        const linkId = ev.target.dataset.linkId
        const page = this.getPageLinkById(linkId)
        const pageType = page.type || 'notesPage'
        let composerData = {}
        if (pageType === 'dailyPage') {
          composerData = {
            date: moment(page.pageName).toDate()
          }
        } else {
          composerData = { link: page.pageLink, label: page.pageName, notesSearch: true }
        }
        if (this.$route.name !== 'Dashboard') {
          this.$router.push({ name: 'Dashboard', params: { changeView: pageType, composerData } })
        } else {
          if (page.type === 'dailyPage') {
            this.$root.$emit('changeComposerView', 'dailyPage', 'COMPOSER', composerData)
          } else {
            this.$root.$emit('changeComposerView', 'notesPage', ev.target.dataset.label, composerData)
          }
        }
      }
      if (ev.target.matches('.datetag')) {
        this.hashtagActive = false
        this.datepicked = parseDisplayDate(ev.target.dataset.date)
        this.query = ''
        this.filteredSuggestions = ['date']
        this.showDatePicker = true
        this.suggestionRange = this.editor.selection
        this.navigatedSuggestionIndex = 0
        this.suggestionPos = { top: `${ev.clientY + 10}px`, left: `${ev.clientX}px` }
        this.inSuggestion = true
      } else if (ev.target.matches('.profiletag')) {
        this.hashtagActive = false
        this.query = ''
        this.showDatePicker = false
        this.filteredSuggestions = this.mentionSuggestions
        this.suggestionRange = this.editor.selection
        this.navigatedSuggestionIndex = this.mentionSuggestions.findIndex(suggestion => suggestion.name === ev.target.outerText)
        this.suggestionPos = { top: `${ev.clientY + 10}px`, left: `${ev.clientX}px` }
        this.inSuggestion = true
      } else {
        if (this.inSuggestion) {
          this.inSuggestion = false
          this.showDatePicker = false
          this.filteredSuggestions = []
        }
      }
    },
    selectSuggestionDate (date) {
      const attrs = {
        id: 'date',
        label: date,
        date,
        photoURL: '',
        initials: '',
        mentionType: 'date'
      }
      this.insertMention({
        range: this.suggestionRange,
        attrs
      })
      this.focus()
    },
    selectSuggestion (suggestion) {
      this.insertMention({
        range: this.suggestionRange,
        attrs: {
          id: suggestion.id,
          label: suggestion.name,
          date: '',
          photoURL: suggestion.photoURL,
          initials: suggestion.initials,
          mentionType: 'profile'
        }
      })
      this.focus()
    },
    selectHashTag  (tag) {
      // console.log('tiptap.selectHashTag', tag)
      const attrs = {}
      attrs['data-hashtag'] = tag.id
      attrs['data-label'] = tag.name
      attrs.label = tag.name
      this.insertHashTag({
        range: this.suggestionRange,
        attrs
      })
      this.focus()
    },
    renderPopup (node) {
    },
    destroyPopup () {
    },
    getRandomColor () {
      const list = [
        '#A975FF',
        '#FB5151',
        '#FD9170',
        '#FFCB6B',
        '#68CEF8',
        '#80CBC4',
        '#9DEF8F'
      ]
      return list[Math.floor(Math.random() * 10000) % list.length]
    },
    getRandomUserName () {
      const rndId = Math.floor(Math.random() * 10000)
      return `User${rndId}`
    },
    async onPageLinkCreate (link) {
      const result = await this.getNotePage({ teamId: this.teamId, id: link.id, notePageName: link.label, notePageLink: link.link })
      return result
    }
  },
  computed: {
    hasResults () {
      return this.filteredSuggestions.length && this.filteredSuggestions[0] !== 'date'
    },
    showSuggestions () {
      return this.query || this.filteredSuggestions.length
    },
    selectedNote () {
      if (this.editor) {
        return this.editor.options.selectedNote
      }
      return false
    }
  },
  created () {
  },
  mounted () {
    this.getRndId()
    let content = `${this.content}`
    if (!this.content) {
      content = ''
    }

    let extensions
    if (this.noteBlocksEnabled) {
      extensions = [NoteDocument]
    } else {
      extensions = [Document]
    }
    extensions = [
      ...extensions,
      Paragraph,
      Text,
      Bold,
      Italic,
      Strike,
      Underline,
      PlaceHolder,
      Blockquote,
      TextAlign,
      BoldMark,
      ItalicMark,
      UnderlineMark,
      StrikeMark,
      TaskList,
      CustomTaskItem,
      Gapcursor,
      NoteKeyExtension.configure({
        tab: this.tab,
        shiftTab: this.shiftTab,
        editorEnter: this.editorEnter,
        backSpace: this.backSpace,
        blockUp: this.blockUp,
        blockDown: this.blockDown
      }),
      Private.configure({
        userid: this.userId
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention'
        },
        suggestion: {
          items: filter => {
            const query = filter.query
            return this.mentionSuggestions.filter(item => {
              return item.name.toLowerCase().startsWith(query.toLowerCase())
            }).slice(0, 10)
          },
          render: () => {
            let component
            let popup
            let editor

            return {
              onStart: props => {
                editor = this.editor
                editor.options.inMenu = true
                component = new VueRenderer(MentionList, {
                  parent: this,
                  propsData: props
                })

                popup = tippy('body', {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => this.$el,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: 'manual',
                  placement: 'bottom-start'
                })
              },
              onUpdate (props) {
                component.updateProps(props)

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect
                })
              },
              onKeyDown (props) {
                return component.ref.onKeyDown(props)
              },
              onExit () {
                editor.options.inMenu = false
                setTimeout(() => {
                  if (popup[0]) {
                    popup[0].destroy()
                  }
                  component.destroy()
                }, 200)
              }
            }
          }
        }
      })
    ]

    if (!this.disableTags) {
      // Hashtags
      extensions = [
        ...extensions,
        Hashtag.configure({
          HTMLAttributes: {
            class: 'hashtag'
          },
          suggestion: {
            items: filter => {
              const query = filter.query
              return this.hashtags.filter(item => {
                return item.name.toLowerCase().startsWith(query.toLowerCase())
              }).slice(0, 10)
            },
            render: () => {
              let component
              let popup
              let editor

              return {
                onStart: props => {
                  editor = this.editor
                  editor.options.inMenu = true
                  component = new VueRenderer(TagList, {
                    parent: this,
                    propsData: props
                  })

                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => this.$el,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start'
                  })
                },
                onUpdate (props) {
                  component.updateProps(props)

                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect
                  })
                },
                onKeyDown (props) {
                  return component.ref.onKeyDown(props)
                },
                onExit () {
                  editor.options.inMenu = false
                  setTimeout(() => {
                    if (popup[0]) {
                      popup[0].destroy()
                    }
                    component.destroy()
                  }, 200)
                }
              }
            }
          }
        })
      ]
    }

    if (this.usePageLinkSuggestions) {
      // Page link suggestions
      extensions = [
        ...extensions,
        PageLinkSuggestion.configure({
          onPageLinkCreate: this.onPageLinkCreate,
          getPageLinkById: this.getPageLinkById,
          suggestion: {
            items: query => {
              return this.pagelinks.filter(item => {
                return item.pageName.toLowerCase().startsWith(query.toLowerCase())
              }).slice(0, 10)
            },
            render: () => {
              let component
              let popup
              let editor

              return {
                onStart: props => {
                  editor = this.editor
                  editor.options.inMenu = true
                  component = new VueRenderer(LinkList, {
                    parent: this,
                    propsData: props
                  })

                  popup = tippy('body', {
                    getReferenceClientRect: props.clientRect,
                    appendTo: () => this.$el,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start'
                  })
                },
                onUpdate (props) {
                  component.updateProps(props)

                  popup[0].setProps({
                    getReferenceClientRect: props.clientRect
                  })
                },
                onKeyDown (props) {
                  return component.ref.onKeyDown(props)
                },
                onExit () {
                  editor.options.inMenu = false
                  setTimeout(() => {
                    if (popup[0]) {
                      popup[0].destroy()
                    }
                    component.destroy()
                  }, 200)
                }
              }
            }
          }
        })
      ]
    }

    if (this.noteBlocksEnabled) {
      // Note blocks
      extensions = [
        ...extensions,
        NoteBlockExtension.configure({
          disableNoteBlockSharing: this.disableNoteBlockSharing
        })
      ]
    }

    extensions = [
      ...extensions,
      History
    ]

    this.editor = new Editor({
      showComments: this.showComments,
      disableNoteBlockSharing: this.disableNoteBlockSharing,
      forDate: this.forDate || null,
      extensions,
      content,
      ydoc: this.ydoc,
      selectedNote: false,
      onUpdate: this.onTextUpdate,
      onFocus: this.onFocus,
      onBlur: this.onBlur,
      onTransaction: this.onTransaction,
      history: false,
      inMenu: false,
      editorProps: {
        handleDOMEvents: {
          drop: (view, e) => {
            const data = e.dataTransfer
            // const types = data.types
            const dataText = data.getData('text/plain') || ''
            if (dataText.indexOf('||task-indicator||') >= 0) {
              // Prevent only task drop
              e.preventDefault()
            }
          }
        }
      }
    })
    const self = this
    this.$nextTick(() => {
      self.$emit('editorReady', self.editor)
    })
    if (this.focused) {
      this.focus()
    }
  },
  watch: {
    content: function (newC, oldC) {
      if (this.content) {
        if (this.htmlContent !== this.content) {
          if (this.editor) {
            this.editor.setContent(this.content, true, { preserveWhitespace: true })
          }
        }
      } else {
        if (this.editor) {
          this.editor.setContent('')
        }
      }
    }
  },
  beforeDestroy () {
    if (this.provider) {
      this.provider.destroy()
    }
    if (this.editor) {
      this.editor.destroy()
    }
    this.destroyPopup()
  }
}
</script>

<style>
.private {
  visibility:hidden;
  background-color:#c7dcf7;
  height:0px;
}
.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13,13,13,.1);
    margin: 0;
}
</style>>

<style lang="scss">

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    align-items: center;

    > input {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }

    &[data-checked="true"] {
      * {
        text-decoration: line-through;
      }
    }

    p {
      margin-top: 3px;
      margin-bottom:3px;
    }
  }
}
.editor {
  color: black;
  background-color: white;
  margin-bottom: 0.1rem;

  &__menu {
    display: flex;
    flex-wrap: wrap;
    padding: 0.25rem;
    border-bottom: 1px solid rgba(black, 0.1);
  }

  &__content {
    padding: 1rem;
    // max-height: 30rem;
    overflow: visible;

    &::-webkit-scrollbar-thumb {
      background-color: rgba(black, 0.1);
    }
  }

  &__bottom-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    white-space: nowrap;
    padding: 0.25rem 0 0.25rem 0.25rem;
    border-top: 1px solid rgba(black, 0.1);
  }

  /* Some information about the status */
  &__status {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    margin-top: 1rem;
    padding: 0.25rem 0.5rem;
    color: rgba(black, 0.5);
    white-space: nowrap;

    &::before {
      content: ' ';
      flex: 0 0 auto;
      display: inline-block;
      width: 0.5rem;
      height: 0.5rem;
      background: rgba(black, 0.5);
      border-radius: 50%;
      margin-right: 0.5rem;
    }

    &--connecting::before {
      background: #FD9170;
    }

    &--connected::before {
      background: #9DEF8F;
    }
  }

  &__actions {
    button {
      background: none;
      border: none;
      font: inherit;
      font-size: 13px;
      font-weight: 500;
      color: rgba(black, 0.5);
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      margin-right: 0.25rem;

      &:hover {
        color: black;
        background-color: rgba(black, 0.05);
      }
    }
  }
}
</style>

<style>
.editor.no-selection .block-wrapper.focused > .block-sharing-icon {
  opacity: 1 !important;
}
.editor .menububble {
  background:white !important;
}
.editor .menububble.is-active {
  opacity: 1;
  display: flex;
  background:rgba(255,255,255,0.95) !important;
  padding: 4px;
  border-radius: 4px;
  margin-top:8px;
}

.editor .menububble__input {
    font: inherit;
    border: none;
    background-color:rgba(255,255,255,0.95) !important;
    color: #333333 !important;
    padding: 4px;
}

.update-input .editor .ProseMirror a {
  color:black;
  cursor:pointer;
}
.update-input .editor .ProseMirror a:hover {
  background-color:#EFEFEF;
}

.menubar__button, .menububble__button {
  color:#999999 !important;
  font-size:16px !important;
  margin-top:6px;
  padding-top:0 !important;
  padding-bottom:0 !important;
  padding-left:2px !important;
  padding-right:2px !important;
  background-color:white;

}

.menububble__button.is-active {
    background-color: rgba(0, 0, 0, 0.1) !important;
}
.menububble__button:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
}

.editor__floating-menu {
  position: absolute !important;
  transition: none !important;
  background:rgba(255,255,255,0.95) !important;
  margin: 0;
  margin-right: auto;
  width: fit-content;
}

.editor__floating-menu.is-active {
  background:transparent;
  padding: 4px;
  border-radius: 4px;
  margin-top: -0.8rem !important;
  margin-left: 6px;
  color:#999999;
  position:absolute !important;
  margin-top:-40px !important;
  left:-10px;
}

.suggestion-list {
    position: fixed;
    z-index: 99;
    color: #333;
    padding: 0px;
    border-radius: 6px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0,0,0,.12);
}
.suggestion-list .suggestion-list__item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.suggestion-list .suggestion-list__item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.suggestion-list .suggestion-list__item {
  padding:6px;
  background-color: white;
  cursor:pointer;
}
.suggestion-list .suggestion-list__item:hover {
  background-color: #DDD;
}
.suggestion-list .suggestion-list__item.is-selected {
  background-color: #CCC
}
.task-line .editor p.is-editor-empty:first-child::before {
  content: 'Describe the task...';
}
.task-desc-line .editor p.is-editor-empty:first-child::before {
  content: 'Type here to add more information to the task...';
}
.editor .block-wrapper.is-empty:first-child p:first-child:before {
  content: 'Add note...';
  position:absolute;
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
  font-style: italic;
}
.editor:focus-within p.is-empty:first-child::before,
.editor:focus-within .block-wrapper.is-empty:first-child p:first-child:before {
  content: '';
}
.editor s * {
  text-decoration: line-through;
}
.editor u s * {
  text-decoration: underline line-through;
  border-bottom: 1px solid;
}
.editor s u * {
  text-decoration: underline line-through;
}

.editor.share-proposal p.is-empty:first-child::before {
  content: 'Add Cover Letter text here';
}

ul[data-type="todo_list"] {
  padding-left: 0;
  margin: 0;
}

li[data-type="todo_item"] {
  display: flex;
  flex-direction: row;
  align-items: center;
  word-break: break-word;
}

.todo-checkbox {
  border: 2px solid black;
  height: 0.9em;
  width: 0.9em;
  box-sizing: border-box;
  margin-right: 10px;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  border-radius: 0.2em;
  background-color: transparent;
  transition: 0.4s background;
}

.todo-content {
  flex: 1;
}

.todo-content p {
  margin:2px;
}

ul[data-type="todo_list"] li[data-done="true"] {
  text-decoration: line-through;
}

ul[data-type="todo_list"] li[data-done="true"] .todo-checkbox {
  background-color: black;
}

ul[data-type="todo_list"] li[data-done="false"] {
  text-decoration: none;
}

ul[data-type="taskList"] > li > div > p {
  min-width:10px;
}

.profiletag .avatar {
    height: 22px;
    width: 22px;
    position: absolute;
    margin-left: -24px;
    margin-top: -4px;
}
.profiletag  {
  height: 20px;
  background-color: #a2bddd;
  color: #fff;
  display: inline-block;
  margin: 0 10px 0 0;
  border-radius: 12px;
  text-transform: none;
  width: max-content;
  min-width: 20px;
  font-size: 10px;
}
.profiletag.suggestion  {
  padding-left: 6px;
  height: 16px;
  line-height:14px;
  font-size: 14px;
}

.profiletag.ProseMirror-selectednode {
  background-color: var(--mdc-theme-primary, #6200ee) !important;
  color: white;
}

.pagelink {
  color: #404040;
  text-decoration: none;
  cursor:pointer;
}
.pagelink::before {
  content: '[[';
  color: #BDBDBD;
}
.pagelink::after {
  content: ']]';
  color: #BDBDBD;
}

.suggestion-scrim {
  position: fixed;
  height:100%;
  width:100%;
  background: transparent;
  opacity: 0;
  top:0;
  left:0;
}

.add-date {
  flex-flow:column;
  display:flex;
  padding:3px;
}

.add-date-header {
  display:flex;
  align-items: center;
  padding-bottom:6px;
}

.add-date .mdc-icon {
  margin-right:6px;
}

.add-date.with-seperator {
  border-top: 1px solid silver;
}

.datetag {
  display: inline-flex;
  align-items: center;
  top: 3px;
  position: relative;
}

.datetag .mdc-icon {
  font-size:16px;
  margin-right:6px;
  margin-left:6px;
}

.editor:not(.content-focused) .editor__floating-menu {
  opacity: 0 !important;
  /* visibility: hidden !important; */
  display: none !important;
}

.editor__content {
  padding: 0;
  min-width: 20px;
}

.annotationMenu {
  border: 1px solid silver;
  padding: 10px;
  border-radius: 6px;
  position: absolute;
  min-width:200px;
}

.annotationMenu .annotationLine {
  display:flex;
}

.annotationMenu .content {
  margin-right:auto;
}

.annotationMenu button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #666666;
    padding: 0.2rem 0.2rem;
    cursor: pointer;
}

.annotationMenu button .mdc-icon {
  font-size:18px;
}

.annotationMenu button:hover {
  background-color: rgba(#999999, 0.05);
}

.annotation {
  background: #dfaef3;
  cursor: pointer;
}

.mention {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  padding: 0px;
  padding-left: 1px;
  padding-right: 1px;
  margin-bottom: 0px;
  flex: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
  display: inline-block;
  max-width: calc(100%);
  font-size: 14px;
  height: 19px;
  line-height: 19px;
  position: relative;
  top: 5px;
  cursor: pointer;
}
.mention::before {
  content: '@';
  color: #666666;
  font-weight: 400;
  position:absolute;
  background:white;
}

</style>

<style lang="scss" scoped>
$color-black: #000000;
$color-white: #ffffff;

.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0rem;
  fill: currentColor;
}

.editor {
  position: relative;

  &__floating-menu {
    position: absolute;
    z-index: 1;
    margin-top: -0.25rem;
    display: none;
    opacity: 0;
    &.is-active {
      opacity: 1;
      display: flex;
    }
  }

}

.editor {
  position: relative;
  margin: 0;

  &__content {
    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $color-black;
      color: $color-white;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }

    p code {
      display: inline-block;
      padding: 0 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

  }
}

.menububble {
  position: absolute;
  display: flex;
  z-index: 20;
  background: $color-black;
  border-radius: 5px;
  padding: 0.3rem;
  margin-bottom: 0.5rem;
  transform: translateX(-50%);
  display: none;
  opacity: 0;
  transition: opacity 0.2s, display 0.2s;

  &__button {
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-white;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: rgba($color-white, 0.1);
    }

    &.is-active {
      background-color: rgba($color-white, 0.2);
    }
  }

  &__form {
    display: flex;
    align-items: center;
  }

  &__input {
    font: inherit;
    border: none;
    background: transparent;
    color: $color-white;
  }
}

.menubar {

  display: flex;
  transition: display 0.2s 0.4s, opacity 0.2s 0.4s;
  position:relative;
  top:13px;

  &.is-hidden {
    display: none;
    opacity: 0;
  }

  &.is-focused {
    display: flex;
    opacity: 1;
    transition: display 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color-black, 0.05);
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }
  }
}

</style>
<style lang="scss">
/*new tiptap menu format styles */
.editor__content {
  ol {
    li::marker {
      font-size: 14px;
    }
  }
}
</style>
